<template>
  <div class="d-flex align-items-center justify-content-between flex-wrap my-2">
    <div v-if="!hideEntriesPerPage" class="d-none d-lg-block">
      <label># de resultados</label>
      <v-select
        v-model="perPageValue"
        :dir="'ltr'"
        :options="perPage"
        :clearable="false"
        class="per-page-selector d-inline-block ml-50 mr-1"
      />
    </div>

    <div :class="{ 'mx-auto': hideEntriesPerPage }">
      <b-pagination
        v-model="pagination.page"
        :total-rows="pagination.total_objects"
        :per-page="pagination.per_page"
        first-number
        last-number
        class="mb-0 mt-1 mt-sm-0"
        prev-class="prev-item"
        next-class="next-item"
        @change="
          (value) => {
            handlePagination({
              page: value,
              per_page: pagination.per_page,
            })
          }
        "
      >
        <template #prev-text>
          <feather-icon icon="ChevronLeftIcon" size="18" />
        </template>
        <template #next-text>
          <b-spinner v-if="loading" small label="Loading" />
          <feather-icon v-else icon="ChevronRightIcon" size="18" />
        </template>
      </b-pagination>
    </div>
  </div>
</template>

<script>
import vSelect from "vue-select"

export default {
  data() {
    return {
      perPage: [10, 25, 50, 100],
    }
  },
  components: {
    vSelect,
  },
  props: {
    loading: {
      type: Boolean,
      default: false,
    },
    handlePagination: {
      type: Function,
      default: () => {},
    },
    pagination: {
      type: Object,
      default: () => ({
        page: 1,
        per_page: 10,
        total_objects: 0,
      }),
    },
    entriesPerPage: {
      type: String,
      default: "10",
    },
    hideEntriesPerPage: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    perPageValue: {
      get() {
        return this.entriesPerPage
      },
      set(value) {
        this.$emit("update:entriesPerPage", value.toString())
      },
    },
  },
}
</script>
